.menu_list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
  }

  .menu_div{
    /* max-width: 45rem !important; */
  }

  .footer_category{
    left: -5%;
  }

  .main_cat_li:hover{
    /* border: 1px solid #f2f3f4;
    border-radius: 5px; */
    height: auto;
    /* padding-right: 1rem; */
}
.SubList li{
    align-items: right !important;
    border: none !important;
    display: flex;
    height: 25px !important;
    /* left: 10px !important; */
    margin: 0 0px 15px 50px !important;
    padding: 7px 0px !important;
    font-size: .9rem !important;
}