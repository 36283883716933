.daily_best_sales_product_card {

    position:absolute;
    bottom: 3%;
}
.popular_product_card {
    position:absolute;
    bottom: 3%;
}

.price_gap{
    padding-right: 100%;
}

.add_to_cart_gap{
    padding-left: 26%;
}

@media screen and (max-width: 480px) {
        .add_to_cart_font{
            font-size: 0.6rem !important;
            padding: 0.3rem !important;
        }
}

@media only screen and (min-width: 1200px) and (max-width: 1443px) {
	.add_to_cart_font{
        font-size: 0.6rem !important;
        padding: 0.3rem !important;
    }
}