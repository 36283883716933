@media screen and (max-width: 1440px) {
  .subscribe_top_img {
    /* top: 2.4rem !important; */
    left: 40rem !important;
    height: 15rem !important;
  }
  .subscribe_div_section {
    top: 26px !important;
  }
  .subscribe_bg{
    height: 20rem;;
  }
}

@media screen and (max-width: 1366px) {
    
  }

  /* On screens that are 1024px wide or less, the background color is blue */
@media screen and (max-width: 1024px) {
  .subscribe_div_section {
    top: 2.6rem !important;
  }
  .subscribe_top_img {
    /* top: 2.4rem !important; */
    left: 33rem !important;
    height: 9rem !important;
  }
  .subscribe_bg{
    height: 15rem !important;
  }
  }
  
  /* On screens that are 768px wide or less, the background color is olive */
  @media screen and (max-width: 768px) {
    .subscribe_div_section {
        top: 15px !important;
      }

    .subscribe_subtext{
        max-width: 15rem;
        font-size: small !important;
        /* top: -105px !important; */

    }
    .subscribe_top_img {
        top: 3.4rem !important;
        left: 20rem !important;
        height: 9rem !important;
      }
  }

  /* On screens that are 600px wide or less, the background color is olive */
  @media screen and (max-width: 480px) {
    .subscribe_div_section {
        top: 26px !important;
      }
      .subscribe_top_img {
        top: 9.6rem !important;
        left: 13rem !important;
        height: 5rem !important;
      }
      .subscribe_email_field{
        max-width: 14rem;
        left: -2px;
      }
      .subscribe_field{
        top: 4px !important;
        left: 0px;
      }
    
  }

  @media screen and (max-width: 375px) {
    .subscribe_div_section {
        top: 30px !important;
        left: 1.8rem;
      }
      .subscribe_top_img {
        top: 9.6rem !important;
        left: 10rem !important;
        height: 4rem !important;
      }
      .subscribe_email_field{
        max-width: 14rem;
        left: -2px;
      }
      .subscribe_field{
        top: 4px !important;
        left: 0px;
      }
    
  }